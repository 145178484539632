<template>
  <page-content>
    <common-table
            ref="table"
            path="web/iot/water/model"
            :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add" >新增</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
        <!--          <action-view @click="view(record)"></action-view>-->
      </template>

    </common-table>

    <model-edit ref="editDrawer" @success="getList"></model-edit>
  </page-content>
</template>

<script>
import ModelEdit from "./ModelEdit"
export default {
  components: {
    ModelEdit
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '型号',
          dataIndex: 'model',
        },
        {
          title: '设备类型',
          dataIndex: 'productKey',
          customRender(text){
            return {
              WATER_MONITOR:"水质监控",
              WATER_CONTROL:"智能控制系统"
            }[text]
          }
        },
        {
          title: '参数数量',
          dataIndex: 'parasJson',
          customRender(text){
            return JSON.parse(text).filter(item=>item.display).length
          }
        },
        {
          title: '开关数量',
          dataIndex: 'switchsJson',
          customRender(text){
            return JSON.parse(text).filter(item=>item.display).length
          }
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createdAt',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 80
        }
      ]
    }
  },
  methods: {

    add(){
      this.$refs.editDrawer.show()
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    view(record){

    },
    getList () {
      this.$refs.table.getData()
    },
    remove (record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/product/model/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
